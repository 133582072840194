<template>
  <div class="pageBox">
		<div class="content">
			<h3 class="title">
        <p class>云测服务</p>
      </h3>
			<div class="cloudBox">
				<p class="text">您可以使用各成员的云测服务，测试您的应用是否存在问题，或验证问题是否已经解决</p>
				<div class="memberListBox">
					<a class="member" href="https://open.oppomobile.com/octpcloud/octpcloud/index.html#/?source=ITGSA " target="_blank">
						<svg-icon svg-name="OPPO" class="oppo"></svg-icon>
						<div class="btn">
							OPPO&nbsp;云测服务
						</div>
					</a>
					<a class="member" href="https://vcl.vivo.com.cn/#/home/index" target="_blank">
						<svg-icon svg-name="vivo" class="vivo"></svg-icon>
						<div class="btn">
							vivo&nbsp;云测服务
						</div>
					</a>
					<a class="member" href="https://testit.miui.com/" target="_blank">
						<svg-icon svg-name="小米logo" class="xiaomi"></svg-icon>
						<div class="btn">
							小米&nbsp;云测服务
						</div>
					</a>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.pageBox {
  padding: 20px;
  border-radius: 5px;
  min-height: calc(100% - 94px); //减底部(20px+ICP高度+20px)
  .content {
    min-height: 800px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(70, 25, 161, 0.1);
    .title {
      position: relative;
      display: flex;
      align-items: center;
      height: 100px;
      padding-left: 49px;
      font-size: 24px;
      font-weight: bold;
      line-height: 100%;
      color: rgba(201, 193, 153, 1);
      background: url(../../../assets/img/console/titleBg.jpg) no-repeat #28252c;
      background-position: 70% 0;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 33px;
        width: 5px;
        height: 24px;
        transform: translateY(-50%);
        border-radius: 3px;
        background: #ded094;
      }  
    }
		.cloudBox{
			.text{
				// height: 60px;
				// line-height: 60px;			
				font-size: 16px;
				margin-left: 32px;
				margin-top: 30px;
				margin-bottom: 9px;
				font-weight: bold;
				color: #666666;
			}
			.memberListBox{
				padding: 0 12px;
				display: flex;
				justify-content:start;
				flex-wrap: wrap;
				.member{
					position: relative;
					width: 493px;
					height: 249px;
					margin: 20px;
					border: 1px solid #EBEBEF;
					overflow: hidden;
					background: #FFFFFF;
					padding-bottom: 40px;
					border-radius: 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					.oppo {
						height: 130px;
						width: 240px;
      		}
      		.vivo {
						height: 130px;
						width: 240px;

					}
					.xiaomi {
						height: 45px;
						width: 166px;
					}
					.btn{
						position: absolute;
						bottom: 0;
						width: 100%;
						height: 62px;
						line-height: 62px;
						text-align: center;
						background: #F3F3F5;
						color: #333333;
						font-size: 14px;	
						font-weight: bold;
						border-top: 1px solid #EBEBEF;
					}
					&:hover {
						border: 1px solid #28262C;
       	 	}
				}
			}
		}
     
  }
}
</style>